import { createSlice } from '@reduxjs/toolkit'
import API from 'app/services/API'
import { USER_MEL, VESSEL_WITH_ID_URL } from '../constants'



const initialState = {
    loader: false,
    value: [],
    vessel: []
}

export const userDetails = createSlice({
    name: 'user',
    initialState,
    reducers: {

        incrementByAmount: (state, action) => {
            console.log(action)
            state.value = action.payload
            state.loader = true
        },
        getVessel: (state, action) => {

            state.vessel = action.payload
        },
    },
})
export const { incrementByAmount, getVessel } = userDetails.actions


export const user_details = () => async(dispatch) => {

    const response = await API.get(USER_MEL);
    if (response.status === 200) {



        localStorage.setItem('vessel', response.data.vesselId)

        dispatch(incrementByAmount(response.data));


    }


}
export const vessel_details = (id, role) => async(dispatch) => {



        if (role.includes('owner')) {
            const response = await API.get(VESSEL_WITH_ID_URL(id));
            if (response.status === 200) {
                dispatch(getVessel(response.data))
            }
        }






    }
    // Action creators are generated for each case reducer function


export default userDetails