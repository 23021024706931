import { configureStore } from "@reduxjs/toolkit";

import loginSlice from './login-slice'
import responseSlice from './response-slice'
import userSlice from "./user-slice";
import meeting from "./meeting-slice";

const store = configureStore({
    reducer: {
        login: loginSlice.reducer,
        response: responseSlice.reducer,
        user_me: userSlice.reducer,
        meeting: meeting.reducer
    },
});

export default store;