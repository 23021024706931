import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: 'login',
    initialState: { isLogin: false },
    reducers: {
        setLogin(state, action) {
            state.isLogin = true;
        },
        setLogout(state, action) {
            state.isLogin = false;
        }

    },
});

export const loginActions = loginSlice.actions;

export default loginSlice;