import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { connect } from "react-redux";
import { user_details,vessel_details } from '../store/user-slice'
class App extends Component {


  state = {}
  componentWillMount(){
    const token =localStorage.getItem('access_token') ? true : false;
    if(token){
    this.props.dispatch(user_details());
    // if(this.props.me){
    //   setTimeout(() => {
    // this.props.dispatch(vessel_details(this.props.me.vesselId,this.props.me.roles));
    //   },[2000])
    // }
  }
  }
  componentDidMount() {
   
    this.onRouteChanged();
  }
  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar user={this.props.me}/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar role={this.props.me}/> : '';
    // let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent  }
          <div className="main-panel">
            <div className="content-wrapper">
              
              <AppRoutes user={this.props.me}/>
              {/* { SettingsPanelComponent } */}
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}
const mapStateToProps = (state) => ({
  me: state.user_me.value,
  vessel:state.user_me.vessel,
  loader:state.user_me.loader
});

export default connect(mapStateToProps)(withRouter(App));
