import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

  
 
  }

  render () {
    console.log(this.props)
    var role=''
    if(this.props.role.roles){

      role =this.props.role.roles.includes("admin") ? 'admin':'owner'
    }
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          {role ==='admin' ?<>
           <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
           <Link className="nav-link" to="/dashboard">
             <i className="mdi mdi-home menu-icon"></i>
             <span className="menu-title">Dashboard</span>
           </Link>
         </li>
          <li className={ this.isPathActive('/vessels') ? 'nav-item active' : 'nav-item' }>
          <Link className="nav-link" to="/vessels">
            <i className="mdi mdi-priority-high  menu-icon"></i>
            <span className="menu-title">Vessels</span>
          </Link>
        </li>
        <li className={ this.isPathActive('/permit') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/permit">
              <i className="mdi mdi-playlist-check menu-icon"></i>
              <span className="menu-title">Permit Library</span>
            </Link>
          </li> <li className={ this.isPathActive('/documents') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/documents">
              <i className="mdi mdi-file-document menu-icon"></i>
              <span className="menu-title">Document Library</span>
            </Link>
          </li><li className={ this.isPathActive('/users') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/users">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title">User Management</span>
            </Link>
          </li> 
          <li className={ this.isPathActive('/hazards') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/hazards">
              <i className="mdi mdi-alert-outline menu-icon"></i>
              <span className="menu-title">Hazards</span>
            </Link>
          </li>
          </>
        : <>
        <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
        <Link className="nav-link" to="/dashboard">
          <i className="mdi mdi-home menu-icon"></i>
          <span className="menu-title">Planning</span>
        </Link>
      </li>
        {/* <li className={ this.isPathActive('/meeting') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/meeting">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title">Planning</span>
            </Link>
          </li> */}
      <li className={ this.isPathActive('/checklist') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/checklist">
              <i className="mdi mdi-playlist-check menu-icon"></i>
              <span className="menu-title">Permit Library</span>
            </Link>
          </li><li className={ this.isPathActive('/documents') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/documents">
              <i className="mdi mdi-file-document menu-icon"></i>
              <span className="menu-title">Document Library</span>
            </Link>
          </li><li className={ this.isPathActive('/crew') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/crew">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title">Crew List</span>
            </Link>
          </li>
          <li className={ this.isPathActive('/risk') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/risk">
              <i className="mdi mdi-alert menu-icon"></i>
              <span className="menu-title">Risk Assessment</span>
            </Link>
          </li>
        
          </>}
       
          {/* <li className={ this.isPathActive('/asset') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/asset">
              <i className="mdi mdi-wrench  menu-icon"></i>
              <span className="menu-title">Asset Management</span>
            </Link>
          </li>

          <li className={ this.isPathActive('/move') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/move">
              <i className="mdi mdi-priority-high  menu-icon"></i>
              <span className="menu-title">Relocate Assets</span>
            </Link>
          </li> */}
         

         
         
          

          

        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);