import { createSlice } from '@reduxjs/toolkit'
import API from 'app/services/API'
import { USER_MEL } from '../constants'


const task = [{ "type": 'textbox', "label": "Task Description", "value": "" },
    { "type": 'checkbox', "label": "Task Specific Risk Assessment Needed", "selected": false },
    {
        "type": 'checkbox1',
        "label": "X Permit Required ",
        "selected": false,
        'option': [
            { "type": 'checkbox', "label": "Risk assesment (Risk register/new RA) ", "selected": false },
            { "type": 'checkbox', "label": "Enclosed space entry (SSF 001) ", "selected": false },
            { "type": 'checkbox', "label": "Pump Room Entry (SSF 002) ", "selected": false },
            { "type": 'checkbox', "label": "Electrical work (SSF 004/004A/004B/004C) ", "selected": false },
            { "type": 'checkbox', "label": "Work at Height (SSF 005) ", "selected": false },
            { "type": 'checkbox', "label": "Pipelines & Pressure vessels (SSF 006) ", "selected": false },
            { "type": 'checkbox', "label": "Hot Work Permit (SSF 007A to SSF 007F) ", "selected": false },
            { "type": 'checkbox', "label": "Underwater work (SSF 008)", "selected": false },
            { "type": 'checkbox', "label": "Hydro blasting (SSF 010)", "selected": false },
            { "type": 'checkbox', "label": "Isolation permit LOTO (SSF 011)", "selected": false },
            { "type": 'checkbox', "label": "Work on Lifts (SSF 014)", "selected": false },
            { "type": 'checkbox', "label": "Work on deck during adverse weather (SSF 017)", "selected": false },
        ]
    },
    { "type": 'checkbox', "label": "Applicable Activities ", "selected": false },
    { "type": 'select', "label": 'Responsibility', 'value': '' },
    { "type": 'hazard', "label": "Hazards", 'value': [] },
    { "type": 'textbox', "label": 'Control Measure', 'value': '' },
    {
        "type": 'checkbox2',
        "label": "Supervisor Required ",
        "selected": false,
        'option': [
            { "type": 'textbox', "label": "Supervisor", "value": '' }
        ]
    },
]

const initialState = {
    value: [
        [{ "type": 'textbox', "label": "Task Description", "value": "" },
            { "type": 'checkbox', "label": "Task Specific Risk Assessment Needed", "selected": false },
            {
                "type": 'checkbox1',
                "label": "X Permit Required ",
                "selected": false,
                'option': [
                    { "type": 'checkbox', "label": "Risk assesment (Risk register/new RA) ", "selected": false },
                    { "type": 'checkbox', "label": "Enclosed space entry (SSF 001) ", "selected": false },
                    { "type": 'checkbox', "label": "Pump Room Entry (SSF 002) ", "selected": false },
                    { "type": 'checkbox', "label": "Electrical work (SSF 004/004A/004B/004C) ", "selected": false },
                    { "type": 'checkbox', "label": "Work at Height (SSF 005) ", "selected": false },
                    { "type": 'checkbox', "label": "Pipelines & Pressure vessels (SSF 006) ", "selected": false },
                    { "type": 'checkbox', "label": "Hot Work Permit (SSF 007A to SSF 007F) ", "selected": false },
                    { "type": 'checkbox', "label": "Underwater work (SSF 008)", "selected": false },
                    { "type": 'checkbox', "label": "Hydro blasting (SSF 010)", "selected": false },
                    { "type": 'checkbox', "label": "Isolation permit LOTO (SSF 011)", "selected": false },
                    { "type": 'checkbox', "label": "Work on Lifts (SSF 014)", "selected": false },
                    { "type": 'checkbox', "label": "Work on deck during adverse weather (SSF 017)", "selected": false },
                ]
            },
            { "type": 'checkbox', "label": "Applicable Activities ", "selected": false },
            { "type": 'select', "label": 'Responsibility', 'value': '' },
            { "type": 'hazard', "label": "Hazards", 'value': [] },
            { "type": 'textbox', "label": 'Control Measure', 'value': '' },
            {
                "type": 'checkbox2',
                "label": "Supervisor Required ",
                "selected": false,
                'option': [
                    { "type": 'textbox', "label": "Supervisor", "value": '' }
                ]
            },
        ]
    ],
}

export const meeting = createSlice({
    name: 'meeting',
    initialState,
    reducers: {

        incrementByAmount: (state, action) => {

            state.value = action.payload
        },
        addTask: (state, action) => {



            state.value.push(task)
        }
    },
})
export const { incrementByAmount, addTask } = meeting.actions




// Action creators are generated for each case reducer function


export default meeting